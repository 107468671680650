import { ColumnForExport } from '../modules/export-requests/ExportAsCsvButton'
import { download } from './download'

/**
 * Converts json array to csv table data. The CSV headers will be extracted from the first element's keys.
 * "Inspired" by this SO answer: https://stackoverflow.com/a/31536517
 * @param {Object[]} jsonArray - An array of objects, to be transformed to CSV. Note that the method used to
 *    stringify each of the values the objects is `JSON.stringify`, so values of type Object/Array/Function/
 *    Component and others will appear as JSONs in the CSV.
 * @returns {String} CSV string, where the first line is the table headers, and each of the next lines
 *    is an entry in the CSV table.
 */
export const jsonArrayToCsv = (jsonArray: Record<string, unknown>[]): string => {
  const replacer = (key: string, value: string | null) => (value === null ? '' : value)

  if (jsonArray.length === 0) return ''

  const header = Object.keys(jsonArray[0])
  if (header.length === 0) return ''

  let csv = jsonArray.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
  csv.unshift(header.join(','))
  return csv.join('\r\n')
}

/**
 * Downloads data as a file. The data can be in format accepted by Blob:
 * https://developer.mozilla.org/en-US/docs/Web/API/Blob/Blob
 * @param {ArrayBuffer|ArrayBufferView|Blob|String} fileContent - The file content.
 * @param {String} fileNameOnceDownloaded - The name of the downloaded file.
 * @param {String} mimetype - Type of file to download. E.g. for CSV values, this should be `text/csv`.
 *    For JSON values, this should be `text/json`. Incomplete list of mime types by file extension:
 *    https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
 *    Mimetype param defaults to `text/plain`.
 */
export const downloadDataAsFile = (
  fileContent: ArrayBuffer | ArrayBufferView | Blob | string,
  fileNameOnceDownloaded: string,
  mimetype: string = 'text/plain'
) => {
  const blob = new Blob([fileContent], { type: `${mimetype};charset=utf-8;` })
  //@ts-ignore
  if (navigator.msSaveBlob) {
    //@ts-ignore IE 10+
    navigator.msSaveBlob(blob, fileNameOnceDownloaded)
  } else {
    // Browsers that support HTML5 download attribute:
    const url = URL.createObjectURL(blob)
    download(url, fileNameOnceDownloaded)
  }
}

/**
 * The result is an array of objects (Record<string, string>[]), where each object represents a transformed
 *  version of an original data item. The keys correspond to column titles, and the values are human-
 *  readable representations derived from the render functions.
 *
 * Example input:
 * 	const datasource = [
 * 	   { name: "Alice", age: 25 },
 *  	   { name: "Bob", age: 30 }
 * 	];
 *
 *	const columns = [
 *	   { title: "Name", render: (item) => item.name.toUpperCase() },
 *	   { title: "Age", render: (item) => `${item.age} years old` }
 *	];
 *
 * Example output:
 * 	[
 * 	   { Name: "ALICE", Age: "25 years old" },
 * 	   { Name: "BOB", Age: "30 years old" }
 *	];
 */
export function convertToReadableData<T extends object>(
  datasource: T[],
  columns: ColumnForExport[]
): Record<string, string>[] {
  return datasource.map(item => {
    return columns.reduce((acc, curr) => {
      if (!curr.title) return acc

      return {
        ...acc,
        //@ts-ignore
        [curr.title]: curr.render(item)
      }
    }, {})
  })
}
